.nopadding {
   padding: 0 !important;
}
#header-wrapper{
    padding: 5px 0px;
    border-bottom: 1px solid #d2d2d2;
}
.header img{
    max-width: 110px;
    margin-top: 10px;
}
.header h6 {
    color: #5f5e5c;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 0px;
}
.header span{
    color: #70a3c5;
    font-size: 12px;
    margin-top: 0px;
    float: left;
}
.header .col-md-4{
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 25px;
}
.form-index .form-control {
    border: 0px;
    padding: 0px;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}
.form-index .form-control label {
    width: 100%;
    color: #797874;
    font-size: 14px;
    float: left;
    margin-bottom: 3px;
}
.captchaFieldContainer input{
    width: 100%;
    float: left;
    font-size: 13px;
    padding-left: 10px;
    height: 33px;
    margin-bottom: 20px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
.captchaFieldContainer .fishIcon{
    top: 5px;
    position: absolute;
    cursor: pointer;
    right: 7px;
    color: #b2b2b2;
}
.captchaFieldContainer .form-control{
    border: 0px;
    padding: 0px;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}
.form-index .form-control input,select{
    width: 100%;
    float: left;
    font-size: 13px;
    padding-left: 10px;
    height: 33px;
    margin-bottom: 1.5em;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
.form-index h6{
    color: #5f5e5c;
    font-size: 13px;
    font-family: sans-serif;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 20px;
    line-height: 20px;
}
.form-index .form-control a{
    position: absolute;
    top: 27px;
    cursor: pointer;
    right: 7px;
    color: #b2b2b2;
}
.form-content{
    color: #5f5e5c;
    line-height: 19px;
    float: left;
    width: 100%;
    font-size: 14px;
    margin-top: -4px;
}
.form-content a{
	color: rgba(19,65,115,1);
}
.form-index button{
	background: rgba(19,65,115,0.6);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    border: 0px;
    height: 40px;
    border-radius: 4px;
    float: left;
    margin-top: 30px;
}
.form-index button:hover{
    background: rgba(19,65,115,1);
}
.footer-text{
    color: #5f5e5c;
    line-height: 18px;
    float: left;
    width: 100%;
    font-size: 13px;
    margin-top: 30px;
}
.footer .col-md-4{
	border-top: 1px solid #f0f0f0;
    margin-top: 20px;
    padding-bottom: 20px;
}
section{
	width: 100%;
	float: left;
}
.footer .col-md-4 img {
    margin-top: 10px;
}
.captcha-box{
    border: 1px solid #d2d2d2;
    text-align: center;
    margin: 20px 0;
    padding: 10px 0;
}
.captcha-index .form-control a{
	top: 5px;
}
.fixed-ft{
	position: fixed;
	bottom: 0px;
}
.looding-index img{
    max-height: 170px;
    margin-bottom: -47px;
}
.looding-index span {
    font-size: 14px;
    font-weight: 500;
}
.looding-index {
    margin-top: 40px;
}
.looding-index1 span {
    font-size: 13px;
    font-weight: 500;
    float: left;
    line-height: 19px;
}
.looding-index1{
	margin-top: 40px;
}
/*.form-index input[type="radio"] {
    width: 15px;
    margin-right: 8px;
    height: 18px;
    margin-top: 6px;
    margin-bottom: 10px;
}*/
.form-index h6 span{
    font-weight: 600;
}
.footer {
    margin-top: 15px;
}




















