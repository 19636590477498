.head{
	background: rgba(19,65,115,1);
	padding: 15px 0;
}
/*.head .ribbon{
    background: rgba(19,65,115,1);
    padding: 10px 0;
}*/
.head h5{
    color: #fff;
    text-align: center;
    font-weight: 300;
    font-family: sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
}
.head a{
	position: absolute;
    right: 6%;
    display: block;
    width: 23px;
    height: 22px;
    top: 15%;
}
.head a:after{
	width: 1px;
    height: 20px;
    background: #fff;
    position: absolute;
    z-index: 1;
    content: "";
    right: 0px;
    top: 0px;
    transform: rotate(-42deg);
}
.head a:before{
	width: 1px;
    height: 20px;
    background: #fff;
    position: absolute;
    z-index: 1;
    content: "";
    right: 0px;
    top: 1px;
    transform: rotate(50deg);
}

html, body {
    height: 100%;
    margin: 0;
}

#mainContainer{
	background-color: #f9fafa;
	/*position: relative;*/
	overflow: hidden;
	min-height: 100%;
	display: block;
}

.waContainer{
	background-color: #ffffff;
	position: relative;
	overflow: hidden;
	display: block;
	min-height: 100vh;
}

.unifiedFlowContent{
	margin: 0px auto;
}

.pannel{
	margin-bottom: 10px;
}

.activeSearch{
	max-width: 100% !important;
    padding: 0px 0px;
}

.site-form p,span,h4,h5,h6{
	color : #5f5e5c;
}

/*.footer-container{
	border-top: 1px solid #d2d2d2;
	padding: 0px 1.14em;
}*/

.fixed-footer{
	border-top: 1px solid #d2d2d2;
	position: fixed;
	bottom: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 10000;
}

.footer-content{
	overflow: hidden;
	padding: 0px 1.14em;
}
.footer-wrapper{
	overflow: hidden;
	margin : 1.07em 0px;
}

.loading {
    background: url(/images/loader.gif) 50% 50% no-repeat;
    margin-bottom: 0.5rem;
    height: 3.357rem;
}

.panel{
	position: relative;
	background-color: #fff;
    padding: 0;
    border-radius: 0.214rem;
    border: none;
}

.inline-spinner {
    position: absolute;
    text-align: left;
    text-indent: -9999rem;
    background:url(/images/loader.gif) no-repeat center center;
    height: 100%;
    width: 100%;
    z-index: 10000;
}

.panel .inline-spinner{
	margin-top: 0rem !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
}

.boxWrapper {
	overflow: hidden;
	background: #fff;
}

#mainWrapper{
	margin-bottom: 3em; 
}

.hide {
    display: none !important;
}