.site-form #header .siteLogoContentWrapper .siteLogo img{
	height : 2.0em;
}

.site-form #header .siteLogoContentWrapper .siteLogo .siteLogoWrapper{
	margin-bottom: 5px;
	margin-top: 1.5em;
}

.site-form #header .siteLogoContentWrapper .siteLogo .siteNameContainer .siteDisplayName h6{
	color: #5f5e5c;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 0px;
}

.site-form #header .siteLogoContentWrapper .siteLogo .siteNameContainer .siteUrl a{
	color: #70a3c5;
    font-size: 12px;
    margin-top: 0px;
    float: left;
}

.site-form .helpText{
	padding: 0 4px;
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 15px;
}

.site-form .helpText h6{
	color: #5f5e5c;
    font-size: 13px;
    font-family: sans-serif;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
}

.site-form .helpText h6 span{
	font-weight:  600;
}

.site-form #header .siteLogoContentWrapper{
	padding: 5px 0px;
	border-bottom: 1px solid #d2d2d2; 
}

.loginContainer{
	margin : 0px auto;
}

.gField.errorField label{
	color:#C91D1D;
}

.form-index .gField.errorCont{
	margin-bottom: 1.5em !important;
}

.gField.errorField input{
	border: 1px solid #C91D1D;
}

.gField.errorField span.inlineError{
	color:#C91D1D;
	position: absolute;
	margin-top: -8px;
	font-size: 13px;
	left: 5px;
    bottom: 0px;
    display: inline !important;
}

.site-form .buttonWrapper{
	margin: 2em 0px;
}

.site-form .buttonWrapper .button{
	text-transform: uppercase;
	font-weight: 500;
	border-radius: 2px;
}

.site-form .buttonWrapper .button{
	text-transform: uppercase;
	border: 0px;
}

.site-form .buttonWrapper .button.full{
    width: 100%;
    height: 40px;
    float: left;
}

.site-form .buttonWrapper .button.norm{
    word-wrap: break-word;
    line-height: 1.2 !important;
    padding: .71429rem .89429rem  !important;
    height: auto;
    vertical-align: top;	
}

.site-form .buttonWrapper .button.primary{
	color: #fff;
}

.site-form .buttonWrapper .button.primary.disabled{
	/*background: #0C76BE;*/
	background: #91beed;
}

.site-form .buttonWrapper .button.primary.enabled{
	background: #19579a;
	/*background: #aed4de;*/
}

.site-form .buttonWrapper .button.primary.enabled:hover{
	background: #134173;
}

.site-form .loginContainer .resetPasswordWrapper{
	width: 100%;
}

.site-form .loginContainer .resetPasswordWrapper span{
	color: #5f5e5c;
    line-height: 19px;
    float: left;
    width: 100%;
    font-size: 14px;
}

.site-form .loginContainer .resetPasswordWrapper span a{
	color: #70a3c5;
}

.site-form .helpSectionFooter{
	width: 100%;
}
.site-form .helpSectionFooter .loginhelptext{
	font-size: 13px;
	float: left;
	line-height: 18px;
	width: 100%;
	margin-bottom: 3em;
}

.singleField .gField .inputWrapper{
	position: relative;
	display: inline-block;
    width: 100%;
}

.singleField .gField .inputWrapper .fishIcon{
	top: 3px;
    position: absolute;
    cursor: pointer;
    right: 7px;
    color: #b2b2b2;
}

.site-form .captchaContainer{
	border: 1px solid #d2d2d2;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px 0;
}

.gField .timerField{
	position: absolute;
	margin-top: -8px;
	font-size: 13px;
	left: 5px;
    bottom: 0px;
}

.mfaQusForm .timerField{
	font-size: 14px;
}

#refreshStatus{
	position: fixed;
	width: 100%;
	top: 50%;
	left: 0;
	margin: 0;
}

#refreshStatus .status-width{
	margin-left: auto;
	max-width: 	19rem;
	margin-right: auto;
	float: none;
}

#refreshStatus .statusMessage{
	font-size: 0.8rem;
    color: #d2d2d2;
    line-height: 1rem;
    padding-right: 0.428rem;
    text-align: center;
    margin-bottom: 40px
}

.uf-error-page .errorSection{
	margin-left: 0.6em;
	margin-top: 0.7em; 
}

.uf-error-page .errorSection .error-title{
	color: #C91D1D;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
}

.uf-error-page .errorSection .error-description{
	margin-top: .85714rem;
    color: #1e1d1c;
    font-size: 0.9rem;
}
.mfaWarpper .infoWrapper strong{
	font-size: 14px;
}
.mfaWarpper .infoWrapper p{
	font-size: 13px;
}

.mfaWarpper .infoWrapper{
	color: #1E1D1C;	
	margin: 0.8em 0.8em 0em 0.8em;
}

.mfaWarpper form{
	margin-top: 10px;
}
.mfaWarpper .mfaQusForm .formFieldWrapper{
	margin: 0 auto;
	color: #77716F;
}

.mfaWarpper .loginForm label.questionLabel{
	margin-top: 32px !important;
	font-size: 14px;
	margin-bottom: 12px;	
}

.mfaWarpper .loginForm .securityQuestionsRadio .item{
	margin: 6px 0px 10px 0px;
	padding: 5px 7px;
	overflow: hidden;
}

.mfaWarpper .loginForm .securityQuestionsRadio .radioLabel{
	font-size: 14px;
	float: left;
}

.mfaWarpper .loginForm .securityQuestionsRadio .radioChoice{
	float: left;
	width: 15px;
    margin-right: 8px;
    height: 18px;
    margin-top: 2px;
}

#popSuggSiteContainer{
	margin-top: 0.78em;
	position: relative;
	-webkit-transition: top .2s;
    -moz-transition: top .2s;
    -o-transition: top .2s;
    z-index: 1;
}

/*#popSuggSiteContainer .siteButtons{
	display: inline-block;
}*/

#popSuggSiteContainer .siteButtons .siteWrapper{
	margin: 0.8rem .47143rem 0 .47143rem;
    box-shadow: none;
    text-align: center;
}

#popSuggSiteContainer .siteButtons .siteWrapper.first{
	margin-left: 0px !important;
}

#popSuggSiteContainer .siteButtons .siteWrapper.last{
	margin-right: 0px !important;
}

#popSuggSiteContainer .siteButton .siteLogoImage{
	padding: 3px 0px;
}

#popSuggSiteContainer .siteButton .siteLogoImage img{
	max-height: 1.6rem;
    height: 1.6rem;
    max-width: 7.0rem;
}

#popularSiteContainer .siteList{
	margin:0 auto;
}

#popSuggSiteContainer .siteButton .siteDisplayName{
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px;
    font-size: 12px;
    line-height: 0.8em;
    margin: 3px 0;
    clear: both;
    color: #1e1d1c;
}

#popSuggSiteContainer .siteButton{
	text-align: center;
    box-shadow: 0 0.07143rem 0.14286rem 0 rgba(0,0,0,0.5);
    border-radius: .21429rem;
    background-color: #fff;
    overflow: hidden;
}

#siteSearch{
	width: 100%;
	background-color: #fff !important;
	height: 3.8em;
	font-size: 0.8rem;
	color: #1e1d1c !important;
	border: none;
	box-shadow: none !important;
	padding-left: 2em;
	outline: none;
	cursor: pointer;
}

.searchBoxContainer{
	position: relative;
	padding: 0 1em;
	border-bottom: 1px solid #bdc1bd;
}
.searchBoxContainer.searchBarEnabled{
	border-bottom: none;
	width: 100%;
    top: 0;
    padding: 0 1.5em;
    position: relative;
    box-shadow: 0 0.14286rem 0.28571rem 0 rgba(0,0,0,0.5)
}

.searchBarEnabled #siteSearch{
	cursor: auto;
}

#searchIcon{
	position: absolute;
    bottom: 0.9em;
    color: #0c76be;

}

.searchClose{
	position: absolute;
    bottom: 0.8em;
    float: right;
    right: 0em;
    display: none;
}

.activeSearch #searchIcon{
	display: none;
}

#backToPopSugg{
	bottom: 0.956rem;
    position: absolute;
    cursor: pointer;
    display: none;
}

.activeSearch #backToPopSugg{
	display: block;
}

.activeSearch .searchClose{
	display: block;
}
.popSuggSearchWrapper .searchContainer .searchBoxContainer{
	margin-top: 0.756em;
}

.siteList .site-item{
	padding: 0 0.4em;
}


.siteList .site-item .favicon img {
    width: 1.143rem;
    height: 1.143rem;
    margin-top: 0.42857rem;
}

.siteList .site-item .favicon img{
	width: 1.143rem;
    height: 1.143rem;
    margin-top: 0.42857rem;
}

.siteList .site-item .favicon{
	margin: .71429rem 0.74286rem 0 0.94286rem;
}

.searchSiteSubContainer .searchSites .site-item .siteName {
    margin: 0 3rem;
    padding-top: .77143rem;
    font-size: 0.8rem;
    line-height: 1.14;
    color: #1e1d1c;
    font-weight: 600;
}
.searchSiteSubContainer .searchSites .site-item .siteUrl {
    margin: 0 3rem;
    padding-bottom: .77143rem;
    border-bottom: .0143rem solid #bdc1bd;
    line-height: 1.17;
    font-size: 0.8rem;
}

.loginContainer .uploadBox{
	background: #fcfcfc;
	padding: 1em 0px;
    border: 1.5px dashed #d2d2d2;
    border-radius: 5px;
}
.loginContainer .uploadBox .uploadBoxWrapper{
	padding: 12px 0px;
}
.loginContainer .uploadBox .uploadImg{
	margin-bottom: 0.8em;
}
.loginContainer .uploadBox .uploadText u{
	color: #19579a;
}

.loginContainer .uploadBox .uploadImg .pdfImge{
	width: 4.7em;
    display: inline-block;
}

.loginContainer .fileUploadField{
	margin-bottom: 1em;
}

.loginContainer .fileUploadField .uploadBox .uploadText{
	margin-bottom: 0px;
    font-size: 13px;
    line-height: 15px;
}

.uploadBox{
	padding-left: 1em !important;
	padding-right: 1em !important;
}

.pdf-slider{
		/*padding: 0.6em;*/
	display: flex;
}
.pdf-slider .owl-stage-outer{
	padding-top: 0.7em;
	padding-bottom: 0.2em;
}
.pdf-slider .slider-item{
	display: flex;
	position: relative;
}

.pdf-slider .slider-item .item-container{
	margin-left: auto;
    margin-right: auto;
	display: table-cell;
	position: relative;
	box-shadow: 0.7px 0.7px 4px #4d4d4d;
}
.pdf-slider .remove-pdf img{
	position: absolute;
    max-height: 1em;
    top: -0.560em;
    right: -0.560em;
    width: auto;
}

.pdf-slider .slider-item .item-container .title{
	font-size: 11px;
	background: white;
	border-bottom: 2px solid #d2d2d2;
	padding: .2em;
	width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pdf-slider .slider-item .item-container .body{
	width: 6.29em;
	text-align: center;
	background: #d2d2d2;

}

.pdf-slider .slider-item .item-container .body img{
	max-height: 7.5em;
	width: auto;
	display: inline-block;
	
}

.pdf-slider .owl-nav{
	position: absolute;
    top: 38%;
    vertical-align: middle;
    width: 100%;
    margin-top: 0px;
}

.pdf-slider .owl-nav .owl-prev{
	float: left;
	margin-left: -0.5em;
}

.pdf-slider .owl-nav .owl-next{
	float: right;
	margin-right: -0.5em;
}

.pdf-slider .owl-nav button{
	background: none;
	width: auto;
}

.pdf-slider .owl-nav [class*=owl-]:hover {
    background: none !important;
    color: #FFF;
    text-decoration: none
}

.pdf-slider .owl-nav [class*=owl-]:focus {
    outline: none;
}

#upload-files{
	position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.statementSelector .statementSelectorWrapper{
	margin-left: auto;
	margin-right: auto; 
}

.statementSelector .statementTypeItems{
	margin-top: 1.4em;
	display: block;
	width: 100%;
}

.wrapper.upload {
	margin-top: 1em;
	text-align: center;
	width: 100%;
}
.wrapper.upload button{
	float: none;
	width: auto;
	border-radius: 14px;
	margin-bottom: 0px;
	margin-top: 0px;
	padding: 6px 60px;
	font-weight: 500;
	font-size: 14px;
}

.wrapper.upload .enabled{
	background: #19579a;
}

.statementSelector .statementTypeItems .statementTypeWrapper{
	position: relative;
    padding: 0px 1.4em;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0.14286rem 0.28571rem 0 rgba(0,0,0,0.5);
    margin-bottom: 2em;
}
.statementSelector .statementTypeItems .statementTypeWrapper:active{
	transform: translateY(1px);
}

.statementSelector .statementTypeIcon .iconWrapper{
	float: left;
    position: relative;
    top: 1.09em;
    overflow: hidden;
}

.statementSelector .statementTypeIcon .iconWrapper img{
	width: 2.8em;
}

.statementSelector .statementTypeWrapper .statementTypeInfo{
	position: relative;
    padding: 0.8em 0px;
    margin-left: 4em;
}

.statementSelector .statementTypeWrapper .statementTypeInfo{
	position: relative;
    padding: 1em 0px;
    margin-left: 4em;
}

.statementSelector .statementTypeWrapper .headerContent{
	font-size: 1.1em;
    font-weight: 700;
    width: auto;
    overflow: hidden;
    color: #5f5e5c;
}

.statementSelector .statementTypeWrapper .suppportContent{
	font-size: 0.79em;
    margin-top: 0.35em;
}

.noResults .noResultContent{
	padding: 1.2em 0em;
	font-size: 14px;
	margin: 0 3.42857rem;
}

.noResults .noResultContent .keyword{
	color: #1e1d1c;
	font-weight: 500;
}

.loginform .sectionWrapper{
	margin-bottom: 	1.49em;
}

.loginform .sectionWrapper .sectionHeader{
	line-height: 1.13;
    margin-bottom: 8px;
    margin-top: 18px;
    font-size: 0.89rem;
    color: #1e1d1c;
    /* font-family: ProximaNovaBold; */
    font-weight: 600;
}

.sectionWrapper .sectionBody .item{
	position: 	relative;
}

.sectionWrapper .sectionBody .item .itemWrapper{
	display: inline-block;
    cursor: pointer;
    line-height: 1.429rem;
    padding-left: 21px;
    height: 1.429rem;
    margin-right: 1.143rem;
    margin-bottom: 0.7143rem;
}

.sectionWrapper .sectionBody .item .itemWrapper label{
	margin-left: 6px;
    font-size: 0.85rem;
}

.sectionWrapper .sectionBody .item .itemWrapper input{
	margin-right: 5px;
    position: absolute;
    left: 1px;
    top: 6px;
    height: 16px;
    width: 16px;
}

#popSuggSiteContainer .siteItem{
	cursor: pointer;
}

#searchSites .site-item{
	cursor: pointer;
}

.errorSection{
	margin: .75714rem 0 .75714rem .75714rem !important;
}

.errorSection .error-title{
	margin-top: 0;
    font-size: 0.97em;
    line-height: 1;
    color: #C91D1D;
    font-weight: 600;
}

.errorSection .error-description{
	margin-top: .55714rem;
    padding-top: 0 !important;
    font-size: 0.8em;
    color: #1e1d1c;
    line-height: 1.25;
}


@media(max-width: 400px){
	.statementSelector .statementTypeItems .statementTypeWrapper{
		margin-bottom: 	1.5em;
		padding: 0px 0.7em;
	}

	.statementSelector .statementTypeIcon .iconWrapper{
		top: 1rem;
	}

	.statementSelector .statementTypeIcon .iconWrapper img {
	    width: 2.3em;
	}

	.statementSelector .statementTypeWrapper .statementTypeInfo {
	    padding: 0.7em 0px;
	    margin-left: 3em;
	}

	.statementSelector .statementTypeWrapper .headerContent {
    font-size: 0.8em;
    font-weight: 700;
    width: auto;
    overflow: hidden;
    color: #5f5e5c;
}

.statementSelector .statementTypeWrapper .suppportContent {
    font-size: 0.6em;
}

}